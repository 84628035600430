import React from 'react';
import ErrorPage from 'next/error';
import { number } from 'prop-types';

class Error extends React.Component {
  static getInitialProps({ res, err, query }) {
    /* eslint-disable prefer-destructuring */
    let statusCode;
    if (res && res.statusCode) {
      statusCode = res.statusCode;
    } else if (err && err.statusCode) {
      statusCode = err.statusCode;
    } else if (query && query.statusCode) {
      statusCode = query.statusCode;
    }
    return { statusCode };
  }

  render() {
    const { statusCode } = this.props;

    return <ErrorPage statusCode={statusCode} />;
  }
}

Error.propTypes = {
  statusCode: number.isRequired,
};

export default Error;
